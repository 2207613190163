import React, { useEffect, useState, useCallback } from 'react';
import Web3Modal from 'web3modal';
import { Notifier } from 'helpers';
import { useUserAddress } from 'eth-hooks'; // replace
import WalletConnectProvider from '@walletconnect/web3-provider';
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useGasPrice, useUserProvider, useContractLoader } from 'hooks';

// Material UI
import theme from 'theme';
import Home from 'pages/Home';
import Burn from 'pages/Burn';
import Bitmo from 'pages/Bitmo';
import Roles from 'pages/Roles';
import Issue from 'pages/Issue';

import Vision from 'pages/Vision';
import Team from 'pages/Team';
import Terms from 'pages/Terms';
import Privacy from 'pages/Privacy';

import NavBar from 'views/NavBar';
import Market from 'pages/Market';
import Profile from 'pages/Profile';
import Transfer from 'pages/Transfer';
import history from 'services/history';
import BitmoScan from 'pages/BitmoScan';
import { MuiThemeProvider } from '@material-ui/core';
import SnackbarProvider from 'components/NotistackBar';
import CssBaseline from '@material-ui/core/CssBaseline';

// Store
import { useStore } from 'context';

// Config
import { INFURA_ID } from 'config';

const blockExplorer = 'https://etherscan.io/';

// Deploying to Mainnet
const mainnetProvider = new JsonRpcProvider(`https://mainnet.infura.io/v3/${INFURA_ID}`);

// Deploying to testnet
const localProviderUrl = 'http://localhost:8545';
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER
  ? process.env.REACT_APP_PROVIDER
  : localProviderUrl;
const localProvider = new JsonRpcProvider(localProviderUrlFromEnv);
console.log('🏠 Connecting to provider:', localProviderUrlFromEnv);

function App() {
  const { actions } = useStore();
  const [injectedProvider, setInjectedProvider] = useState();
  // const { loading, error, data } = useQuery(GET_TRANSFERS);
  // const [injectedProvider, web3Modal, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();

  // 🔥 this hook will get the price of Gas from ⛽️ EtherGasStation
  const gasPrice = useGasPrice('fast');

  // Use your injected provider from 🦊 Metamask
  const userProvider = useUserProvider(injectedProvider, localProvider); //
  const address = useUserAddress(userProvider);

  // The transactor wraps transactions and provides notificiations
  const nautifier = Notifier(userProvider, gasPrice);

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider);

  // If you want to make 🔐 write transactions to your contracts, use the userProvider:
  const writeContracts = useContractLoader(userProvider);

  useEffect(() => {
    if (readContracts && writeContracts && address) {
      // Store Read Contracts in Context
      actions.setReadContracts(readContracts);

      // Store Write Contracts in Context
      actions.setWriteContracts(writeContracts);

      // Store nautifier in Context
      actions.setNautifier(nautifier);

      // Store walletAddress in Context
      actions.setAddress(address);
    }
  }, [address, readContracts, writeContracts]);

  // React.useEffect(() => {
  //   if (!loading && !error && data && data.transfers) {
  //     console.log({ transfers: data.transfers });
  //   }
  // }, [loading, error, data]);

  const web3Modal = new Web3Modal({
    // network: NETWORK,
    cacheProvider: true,
    theme: 'dark',
    providerOptions: {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          INFURA_ID,
        },
      },
    },
  });

  // Open wallet selection modal.
  const loadWeb3Modal = useCallback(async () => {
    const newProvider = await web3Modal.connect();

    setInjectedProvider(new Web3Provider(newProvider));
  }, [setInjectedProvider]);

  const logoutOfWeb3Modal = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      setTimeout(() => {
        window.location.reload();
      }, 1);
    },
    [web3Modal],
  );

  // Ethereum Event listeners
  window.ethereum &&
    window.ethereum.on('accountsChanged', (accounts) => {
      if (accounts.length > 0) {
        setTimeout(() => {
          window.location.reload();
        }, 1);
      }
    });

  window.ethereum &&
    window.ethereum.on('chainChanged', (chainId) => {
      setTimeout(() => {
        window.location.reload();
      }, 1);
    });

  // If user has loaded a wallet before, load it automatically.
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal, web3Modal.cachedProvider]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <Router history={history}>
          <NavBar
            address={address}
            localProvider={localProvider}
            userProvider={userProvider}
            mainnetProvider={mainnetProvider}
            web3Modal={web3Modal}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            blockExplorer={blockExplorer}
          />
          <Switch>
            <Route path='/bitmo/:tokenId/transfer' exact component={Transfer} />
            <Route path='/bitmo/:tokenId/burn' exact component={Burn} />
            <Route path='/bitmo/:tokenId' exact component={Bitmo} />
            <Route path='/profile/roles' exact component={Roles} />
            <Route path='/explorer' exact component={BitmoScan} />
            <Route path='/profile' exact component={Profile} />
            <Route path='/projects' exact component={Market} />
            {/*  */}
            <Route path='/vision' exact component={Vision} />
            <Route path='/team' exact component={Team} />
            <Route path='/terms' exact component={Terms} />
            <Route path='/privacy' exact component={Privacy} />

            {/*  */}
            <Route path='/issue' exact component={Issue} />
            <Route path='/' exact component={Home} />
          </Switch>
        </Router>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default App;
