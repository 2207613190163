import React from 'react';
import Bitmos from 'views/Bitmos';
import { useAllBitmos } from 'hooks';
import Button from 'components/Button';
import { Grid } from '@material-ui/core';
import Typography from 'components/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// Store
import { useStore } from 'context';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    textAlign: 'center',
    marginTop: theme.spacing(9),
  },
  hook: {
    marginRight: 64,
    marginBottom: 64,
  },
  explore: {
    borderColor: 'white',
    verticalAlign: 'top',
    marginLeft: 8,
    marginTop: 8,
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.secondary.background,
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const Market = () => {
  // const {
  //   state: { readContracts },
  // } = useStore();
  const classes = useStyles();
  // const allBitmos = useAllBitmos(readContracts);

  // return <>
  // {/* {allBitmos && <Bitmos bitmos={allBitmos} page='projects' />} */}

  // </>;

  return (
    <section className={classes.root}>
      <Grid container wrap='nowrap' className={classes.container}>
        <Grid container item xs alignItems='flex-end' justify='center' />

        <Grid container item xs={11} className={classes.image}>
          <Grid
            item
            container
            direction='column'
            justify='flex-end'
            justifyContent='center'
            alignItems='center'
            className={classes.hook}
          >
            <Grid item>
              <Typography
                variant='h4'
                style={{
                  background: 'black',
                  webkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Coming Soon!
              </Typography>
              <Typography
                variant='h4'
                style={{
                  background: 'black',
                  webkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                BITMO projects will appear here as they are issued.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs alignItems='flex-end' justify='center' />
      </Grid>
    </section>
  );
};

export default Market;
